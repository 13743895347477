<template>
  <Layout>
    <section class="csms-hq">

      <!-- Info Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body pb-0">

              <div class="row" v-if="!$_utils.isEmpty(item)">
                <!-- HQ View Start -->
                <div class="col-xl-9">
                  <div class="card shadow-none">
                    <div class="card-body">

                      <div class="float-end">
                        <b-button size="sm" variant="warning" @click="edit">
                          <i class="uil uil-edit me-2"></i>
                          <span>{{ $t('btn.edit') }}</span>
                        </b-button>
                      </div>
                      <div class="mb-3">
                        <span class="font-size-18 fw-bold pe-4">{{ item.hqName }}</span>
                        <span class="font-size-14">{{ item.created }} {{ $t('headquarters.join') }}</span>
                      </div>

                      <div class="row align-items-center">

                        <div class="col-xl-5 col-sm-6">
                          <ItemProfileLayout :icon-class-name="'uil uil-building'">
                            <div>{{ item.address }}</div>
                            <DividedItems v-if="item.hqRepresentAccount"
                                          :items="[ item.hqRepresentAccount.name , item.hqRepresentAccount.mobile ]" />
                            <div v-else>{{ $t('headquarters.managerUnspecified') }}</div>
                            <DividedItems :items="[ $t('headquarters.businessNo') , item.businessNo ?? '-' ]" />
                          </ItemProfileLayout>
                        </div>

                        <div class="col-xl-4 col-sm-6">
                          <ItemProfileLayout :icon-class-name="'mdi mdi-cash'" :icon-size="'36px'">
                            <BadgeTitleItems v-if="item.chargePlacePlan"
                                             :items="[
                                                 { title: $t('headquarters.planName'), content: `${item.chargePlacePlan.planName} (${ $t('headquarters.profitDistRate') } ${item.chargePlacePlan.profitDistRate} %)` },
                                                 { title: $t('headquarters.serverUsageFee'), content: `${item.serverUsageFee ?? item.chargePlacePlan.serverUsageFee.toLocaleString()} ¥/${ $t('headquarters.month')}` },
                                                 { title: $t('headquarters.settleRate'), content: `${item.settleRate ?? item.chargePlacePlan.settleRate} %` }
                                             ]" />
                            <BadgeTitleItems v-else
                                             :items="[
                                                 { title: $t('headquarters.planName'), content: null },
                                                 { title: $t('headquarters.serverUsageFee'), content: null },
                                                 { title: $t('headquarters.settleRate'), content: null }
                                             ]" />
                          </ItemProfileLayout>
                        </div>

                        <div class="col-xl-3 col-sm-6">
                          <template v-if="item.logoFile">
                            <div class="row d-flex align-items-center">
                              <div class="col-auto">
                                <b-img v-if="item.logoFile"
                                       :src="item.logoFile.filePath"
                                       class="me-2"
                                       style="object-fit: cover"
                                       width="40" height="40" center
                                       rounded="circle"
                                       alt="point logo image" />
                              </div>
                              <div class="col">
                                <BadgeTitleItems :items="[
                                      { title: $t('headquarters.pointSystem'), content: item.isAllowPointSaving === true ? `${ $t('common.use') }` : `${ $t('common.unused') }` },
                                      { title: $t('headquarters.pointSaveRate'), content: `${item.pointSavingRate ? `${item.pointSavingRate}%` : '' }`}
                                    ]" />
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <ItemProfileLayout :icon-class-name="'uil uil-award'" :icon-size="'34px'">
                              <BadgeTitleItems :items="[
                                  { title: $t('headquarters.pointSystem'), content: item.isAllowPointSaving === true ? `${ $t('common.use') }` : `${ $t('common.unused') }` },
                                  { title: $t('headquarters.pointSaveRate'), content: `${item.pointSavingRate ? `${item.pointSavingRate}%` : '' }`}
                                ]"/>
                            </ItemProfileLayout>
                          </template>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <!-- HQ View End -->

                <!-- Price Start -->
                <div class="col-xl-3">
                  <div class="card card-h-100 shadow-none">
                    <div class="card-body">

<!--                      // TODO: 본부 요금 수정 팝업-->
<!--                      <div class="row justify-content-end">
                        <b-button class="col-auto" size="sm" variant="outline-warning" @click="edit">
                          <i class="uil uil-edit me-2"></i>
                          <span>{{ $t('btn.edit') }}</span>
                        </b-button>
                      </div>-->

                      <div class="flex-grow-1 h-100 d-flex align-items-center">
                        <ItemProfileLayout :icon-class-name="'mdi mdi-currency-jpy'" :icon-size="'34px'">
                          <div v-if="item.paymentUnitPrice" class="badge rounded-pill badge-outline-primary font-size-12 mb-2">{{ item.paymentUnitPrice.priceName }}</div>
                          <BadgeTitleItems v-if="item.paymentUnitPrice"
                                           :items="[
                                               { title: $t('common.slow'), content: `${item.paymentUnitPrice.slowSellingPrice} ¥/kWh` },
                                               { title: $t('common.fast'), content: `${item.paymentUnitPrice.fastSellingPrice} ¥/kWh` }
                                           ]" />
                          <BadgeTitleItems v-else
                                           :items="[
                                               { title: $t('common.slow'), content: null },
                                               { title: $t('common.fast'), content: null }
                                           ]" />
                        </ItemProfileLayout>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- Price End -->
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Info End -->

      <!-- Charge Point Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount"/>
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('headquarters.noCp')"
                         :fields="fields" :items="cpItems">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`headquarters.${data.column}`) }}</span>
                  </template>

                  <template #cell(cpAddress)="data">
                    {{ $_utils.textLengthOverCut(data.item.address, 40, null) }}
                  </template>
                  <template #cell(detailLocation)="data">
                    {{ !$_utils.isEmpty(data.item.detailLocation) ? data.item.detailLocation : '-' }}
                  </template>
                  <template #cell(chargerInfo)="data">
                    <div>
                      <span class="me-2">{{ $t('common.slow') }}</span>{{ data.item.chargerTypeCounts[0].count }}<br/>
                      <span class="me-2">{{ $t('common.fast') }}</span>{{ data.item.chargerTypeCounts[1].count }}
                    </div>
                  </template>
                  <template #cell(cpPlan)="data">
                    {{ data.item.chargePlacePlan ? data.item.chargePlacePlan.planName : '-' }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Charge Point End -->

      <!-- Bottom Button Start -->
      <div class="row mb-3">
        <div class="col-xl-12">
          <div class="float-start" v-if="$_app.user.roles[0] === 'ADMIN'">
            <b-button variant="danger" @click="del">
              <i class="uil uil-minus me-2"></i>
              <span>{{ $t('btn.hqDelete') }}</span>
            </b-button>
          </div>
          <div class="float-end">
            <b-button variant="outline-secondary" @click="$router.go(-1)">
              <i class="uil uil-list-ul me-2"></i>
              <span>{{ $t('btn.list') }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- Bottom Button End -->

      <edit-modal ref="hqEditModal" :selected-id="selectedId" @callback="getItem" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import EditModal from '@/views/pages/headquarters/HqEditModal.vue';
import ItemProfileLayout from '@/views/components/ItemProfileLayout.vue';
import DividedItems from '@/views/components/DividedItems.vue';
import BadgeTitleItems from "@/views/components/BadgeTitleItems.vue";

export default {
  components: {
    Layout, PerPageSelector, TotalCount, EditModal, ItemProfileLayout, DividedItems, BadgeTitleItems
  },
  data() {
    return {
      selectedId: null,
      item: null,
      pagination: {
        currentPage: 1,
        totalCount: 0,
        perPage: 10
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'placeName', label: '충전소명', class: 'text-center' },
        { key: 'cpAddress', label: '주소', class: 'text-center' },
        { key: 'detailLocation', label: '상세 위치', class: 'text-center' },
        { key: 'useTime', label: '운영 시간', class: 'text-center' },
        { key: 'chargerInfo', label: '충전기 정보', class: 'text-center' },
        { key: 'cpPlan', label: '충전소 플랜', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' }
      ],
      cpItems: null
    }
  },
  created() {
    const vm = this;

    vm.selectedId = this.$route.params.id;
  },
  mounted() {
    const vm = this;

    vm.getItem();
    vm.getCpItems();
  },
  methods: {
    ...loadingMethods,
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Hq/retrieve/${vm.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
      }).catch((err) => {
        console.error('Headquarters Retrieve Error !!', err)
      });
    },
    getCpItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.showOverlay();

      http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: [{
          'searchKey': 'hqId',
          'searchValue': vm.selectedId
        }],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.cpItems = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Charge Place List Error :: ', err)
        vm.hideOverlay();
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getCpItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getCpItems();
        vm.hideOverlay();
      }
    },
    edit() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.hqEditModal.show();
      });
    },
    del() {
      const vm = this;

      if (vm.cpItems.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.haveCpNoDelete'),
          confirmButtonText: i18n.t('btn.close')
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmDelete'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.delItem();
          }
        });
      }
    },
    delItem() {
      const vm = this;
      const _url = `/onmapi/view/Hq/delete/${vm.selectedId}`;

      http.delete(_url).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.deleted'),
            confirmButtonText: i18n.t('btn.close')
          });
          this.$router.go(-1);
        }
      }).catch(err => {
        console.error('Headquarters Delete Error :: ', err);

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
